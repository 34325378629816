import { TTF, WOFF2 } from './formats'

export const CormorantGaramond = [
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-Bold.ttf',
    format: TTF,
    descriptors: {
      style: 'normal',
      weight: 700,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-BoldItalic.ttf',
    format: TTF,
    descriptors: {
      style: 'italic',
      weight: 700,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-SemiBold.ttf',
    format: TTF,
    descriptors: {
      style: 'normal',
      weight: 600,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-SemiBoldItalic.ttf',
    format: TTF,
    descriptors: {
      style: 'italic',
      weight: 600,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-Medium.ttf',
    format: TTF,
    descriptors: {
      style: 'normal',
      weight: 500,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-MediumItalic.ttf',
    format: TTF,
    descriptors: {
      style: 'italic',
      weight: 500,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-Regular.ttf',
    format: TTF,
    descriptors: {
      style: 'normal',
      weight: 400,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-Italic.ttf',
    format: TTF,
    descriptors: {
      style: 'italic',
      weight: 400,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-Light.ttf',
    format: TTF,
    descriptors: {
      style: 'normal',
      weight: 300,
    },
  },
  {
    family: 'CormorantGaramond',
    source: '/fonts/CormorantGaramond-LightItalic.ttf',
    format: TTF,
    descriptors: {
      style: 'italic',
      weight: 300,
    },
  },
]

export const Butler = [
  {
    family: 'Butler',
    source: '/fonts/Butler-Black.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 900,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-ExtraBold.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 800,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-Bold.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 700,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-Medium.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 500,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-Regular.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 400,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-Light.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 300,
    },
  },
  {
    family: 'Butler',
    source: '/fonts/Butler-UltraLight.woff2',
    format: WOFF2,
    descriptors: {
      style: 'normal',
      weight: 100,
    },
  },
]

export default [...CormorantGaramond, ...Butler]
