





















































import { Color, colors } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'

type ButtonType = 'button' | 'reset' | 'submit'
export type ButtonVariant = 'solid' | 'ghost' | 'soft' | 'transparent'

export const buttonToneConfig: Record<
  Color,
  Record<ButtonVariant, (string | Record<string, boolean>)[]>
> = {
  primary: {
    solid: ['text-primary-inverse', 'bg-primary-900', 'hover:bg-primary-800'],
    ghost: ['text-primary', 'hover:bg-primary-50'],
    soft: [
      'text-primary-900',
      'bg-primary-100',
      'hover:bg-primary-200',
      'dark:hover:border-primary-700',
      'dark:hover:bg-primary-700',
      'dark:text-primary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-primary', 'hover:bg-primary-100'],
  },
  secondary: {
    solid: [
      'text-secondary-inverse',
      'bg-secondary-900',
      'hover:bg-secondary-800',
    ],
    ghost: ['text-secondary', 'hover:bg-secondary-50'],
    soft: [
      'text-secondary-700',
      'bg-secondary-200',
      'hover:bg-secondary-200',
      'dark:hover:bg-secondary-700',
      'dark:text-secondary-100',
      'dark:hover:bg-secondary-40',
    ],
    transparent: ['text-secondary', 'hover:bg-secondary-100'],
  },
  tertiary: {
    solid: [
      'text-tertiary-inverse',
      'bg-tertiary-900',
      'hover:bg-tertiary-800',
    ],
    ghost: ['text-tertiary', 'hover:bg-tertiary-50'],
    soft: [
      'text-tertiary-700',
      'bg-tertiary-50',
      'hover:bg-tertiary-100',
      'dark:hover:bg-tertiary-700',
      'dark:text-tertiary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-tertiary', 'hover:bg-tertiary-100'],
  },
  quaternary: {
    solid: [
      'text-quaternary-inverse',
      'bg-quaternary-900',
      'hover:bg-quaternary-800',
    ],
    ghost: ['text-quaternary', 'hover:bg-quaternary-50'],
    soft: [
      'text-quaternary-700',
      'bg-quaternary-50',
      'hover:bg-quaternary-100',
      'dark:hover:bg-quaternary-700',
      'dark:text-quaternary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-quaternary', 'hover:bg-quaternary-100'],
  },
  quinary: {
    solid: ['text-quinary-inverse', 'bg-quinary-900', 'hover:bg-quinary-800'],
    ghost: ['text-quinary', 'hover:bg-quinary-50'],
    soft: [
      'text-quinary-700',
      'bg-quinary-50',
      'hover:bg-quinary-100',
      'dark:hover:bg-quinary-700',
      'dark:text-quinary-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-quinary', 'hover:bg-quinary-100'],
  },
  critical: {
    solid: [
      'text-critical-inverse',
      'bg-critical-900',
      'hover:bg-critical-800',
    ],
    ghost: ['text-critical', 'hover:bg-critical-50'],
    soft: [
      'text-critical-700',
      'bg-critical-50',
      'hover:bg-critical-100',
      'dark:hover:bg-critical-700',
      'dark:text-critical-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-critical', 'hover:bg-critical-100'],
  },
  caution: {
    solid: ['text-caution-inverse', 'bg-caution-900', 'hover:bg-caution-800'],
    ghost: ['text-caution', 'hover:bg-caution-50'],
    soft: [
      'text-caution-700',
      'bg-caution-50',
      'hover:bg-caution-100',
      'dark:hover:bg-caution-700',
      'dark:text-caution-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-caution', 'hover:bg-caution-100'],
  },
  positive: {
    solid: [
      'text-positive-inverse',
      'bg-positive-900',
      'hover:bg-positive-800',
    ],
    ghost: ['text-positive', 'hover:bg-positive-50'],
    soft: [
      'text-positive-700',
      'bg-positive-50',
      'hover:bg-positive-100',
      'dark:hover:bg-positive-700',
      'dark:text-positive-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-positive', 'hover:bg-positive-100'],
  },
  neutral: {
    solid: ['text-neutral-inverse', 'bg-neutral-900', 'hover:bg-neutral-800'],
    ghost: ['text-neutral', 'hover:bg-neutral-50'],
    soft: [
      'text-neutral-700',
      'bg-neutral-100',
      'hover:bg-neutral-200',
      'dark:hover:bg-neutral-700',
      'dark:text-neutral-200',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-neutral', 'hover:bg-neutral-100'],
  },
  info: {
    solid: ['text-info-inverse', 'bg-info-900', 'hover:bg-info-800'],
    ghost: ['text-info', 'hover:bg-info-50'],
    soft: [
      'text-info-700',
      'bg-info-50',
      'hover:bg-info-100',
      'dark:hover:bg-info-700',
      'dark:text-info-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-info', 'hover:bg-info-100'],
  },
  promote: {
    solid: ['text-promote-inverse', 'bg-promote-900', 'hover:bg-promote-800'],
    ghost: ['text-promote', 'hover:bg-promote-50'],
    soft: [
      'text-promote-700',
      'bg-promote-50',
      'hover:bg-promote-100',
      'dark:hover:bg-promote-700',
      'dark:text-promote-100',
      'dark:hover:bg-opacity-40',
    ],
    transparent: ['text-promote', 'hover:bg-promote-100'],
  },
}

export const buttonBaseClasses = [
  'w-full',
  'sm:w-auto',
  'font-normal',
  'text-lg',
  'text-center',
  'py-2',
  'px-12',
  'rounded',
  'transition-colors',
  'duration-200',
  'focus:outline-none',
  'focus-visible:ring-2',
  'dark:bg-opacity-90',
]

export const buttonVariantConfig: Record<ButtonVariant, string[]> = {
  solid: ['border-4', 'border-transparent'],
  ghost: ['border-4', 'border-current'],
  soft: [],
  transparent: ['border-4', 'border-transparent'],
}

export default defineComponent({
  name: 'BaseButton',

  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'button',
      validator: (value: string) =>
        ['button', 'reset', 'submit'].includes(value),
    },

    tone: {
      type: String as PropType<Color>,
      default: 'neutral' as Color,
      validator: (value: Color) => colors.includes(value),
    },

    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'solid' as ButtonVariant,
      validator: (value: string) =>
        ['solid', 'ghost', 'soft', 'transparent'].includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const computedClass = computed(() => [
      ...buttonBaseClasses,
      ...buttonToneConfig[props.tone][props.variant],
      ...buttonVariantConfig[props.variant],
      ...(props.disabled ? ['opacity-50'] : []),
      ...(props.loading ? ['relative pointer-events-none select-none'] : []),
    ])

    const computedAttributes = computed(() => ({
      disabled: props.disabled || props.loading,
    }))

    return {
      computedClass,
      computedAttributes,
    }
  },
})
