import base from './base'
import contact from './contact'
import security from './security'
import rsvp from './rsvp'

export const api = {
  base,
  security,
  contact,
  rsvp,
}
