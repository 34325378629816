

























































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import BaseText from '@/components/_base/BaseText.vue'
import BaseBox from '@/component/_base/BaseBox.vue'
import { SiteHeaderItemConfig } from '@/composition/siteHeader'
import appConfig from '@/app.config.json'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import { currentLanguage } from '@/utils/language'

export default defineComponent({
  name: 'SiteHeader',

  components: {
    BrandLogo,
  },

  props: {
    items: {
      type: Array as PropType<SiteHeaderItemConfig[]>,
      required: true,
    },
  },

  setup() {
    const { year, month, day } = appConfig.wedding.date
    const weddingDate = new Date(year, month, day)
    const dayLabel = computed(() =>
      weddingDate.toLocaleDateString(currentLanguage.value.locale, {
        day: '2-digit',
      })
    )
    const monthLabel = computed(() =>
      weddingDate.toLocaleDateString(currentLanguage.value.locale, {
        month: '2-digit',
      })
    )

    const logo = ref<InstanceType<typeof BrandLogo> | null>(null)
    const venue = ref<InstanceType<typeof BaseText> | null>(null)
    const date = ref<InstanceType<typeof BaseBox> | null>(null)
    const nav = ref<InstanceType<typeof BaseBox> | null>(null)

    onMounted(() => {
      if (!logo.value || !venue.value || !date.value || !nav.value) return

      const elements = [logo.value.$el, venue.value.$el, date.value.$el]
      const navElements = nav.value.$el.children

      const tl = gsap.timeline()

      tl.set(navElements, {
        opacity: 0,
        x: -10,
      })

      tl.fromTo(
        elements,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'expo.in',
          delay: 0.5,
          duration: 1,
        }
      )

      tl.to(navElements, {
        opacity: 1,
        x: 0,
        ease: 'expo.out',
        delay: 1,
        duration: 1,
        stagger: 0.2,
      })
    })

    return {
      title: appConfig.title,
      dayLabel,
      monthLabel,
      logo,
      venue,
      date,
      nav,
    }
  },
})
