








import i18n from '@/setup/i18n'
import { esMx } from '@/utils/language'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { formatDistance, parseISO } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

export default defineComponent({
  name: 'BaseDate',

  props: {
    tag: {
      type: String,
      default: 'time',
    },

    date: {
      type: [String, Date] as PropType<string | Date>,
      required: true,
    },

    now: {
      type: Date as PropType<Date>,
      default: () => new Date(),
    },

    distance: {
      type: Boolean,
      default: false,
    },

    localeMatcher: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['localeMatcher'] | undefined
      >,
      default: undefined,
    },

    weekday: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['weekday'] | undefined
      >,
      default: undefined,
    },

    era: {
      type: String as PropType<Intl.DateTimeFormatOptions['era'] | undefined>,
      default: undefined,
    },

    year: {
      type: String as PropType<Intl.DateTimeFormatOptions['year'] | undefined>,
      default: undefined,
    },

    month: {
      type: String as PropType<Intl.DateTimeFormatOptions['month'] | undefined>,
      default: undefined,
    },

    day: {
      type: String as PropType<Intl.DateTimeFormatOptions['day'] | undefined>,
      default: undefined,
    },

    hour: {
      type: String as PropType<Intl.DateTimeFormatOptions['hour'] | undefined>,
      default: undefined,
    },

    minute: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['minute'] | undefined
      >,
      default: undefined,
    },

    second: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['second'] | undefined
      >,
      default: undefined,
    },

    timeZoneName: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['timeZoneName'] | undefined
      >,
      default: undefined,
    },

    formatMatcher: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['formatMatcher'] | undefined
      >,
      default: undefined,
    },

    hour12: {
      type: Boolean as PropType<
        Intl.DateTimeFormatOptions['hour12'] | undefined
      >,
      default: undefined,
    },

    timeZone: {
      type: String as PropType<
        Intl.DateTimeFormatOptions['timeZone'] | undefined
      >,
      default: undefined,
    },

    options: {
      type: Object as PropType<Intl.DateTimeFormatOptions | undefined>,
      default: undefined,
    },

    fallbackLocale: {
      type: String,
      default: 'en-US',
    },
  },

  setup(props) {
    const dateTimeFormatOptions = computed<Intl.DateTimeFormatOptions>(
      () =>
        props.options || {
          localeMatcher: props.localeMatcher,
          weekday: props.weekday,
          era: props.era,
          year: props.year,
          month: props.month,
          day: props.day,
          hour: props.hour,
          minute: props.minute,
          second: props.second,
          timeZoneName: props.timeZoneName,
          formatMatcher: props.formatMatcher,
          hour12: props.hour12,
          timeZone: props.timeZone,
        }
    )

    const computedDate = computed(() => {
      let date: Date
      if (!props.date) date = props.now
      date = typeof props.date === 'string' ? parseISO(props.date) : props.date
      return date
    })

    const formattedDate = computed(() => {
      if (props.distance) {
        // date-fns does not support 'es-MX' locale, use 'es' instead
        return formatDistance(computedDate.value, props.now, {
          locale: i18n.locale === esMx.locale ? es : enUS,
          addSuffix: true,
          includeSeconds: true,
        })
      }

      return computedDate.value.toLocaleDateString(
        i18n.locale || props.fallbackLocale,
        dateTimeFormatOptions.value
      )
    })

    return {
      dateTimeFormatOptions,
      formattedDate,
    }
  },
})
