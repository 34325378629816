






import appConfig from '@/app.config.json'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BrandLogo',

  setup() {
    const { bride, groom } = appConfig.wedding

    const braidAndGroomInitials = computed(
      () => `${bride.name.charAt(0)}&${groom.name.charAt(0)}`
    )

    return {
      braidAndGroomInitials,
    }
  },
})
